<template>
  <div class="bg-white">
    <app-modal-confirm
      ref="entry_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteEntry()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <app-modal-customer
      @selectCustomer="selectCustomer"
      :endPoint="endPoint"
      :idEditing="idEditing"
      :screenFlag="screenFlag"
      ref="modal_list_customer">
    </app-modal-customer>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
        <div class="card-body">
          <div v-if="idEditing">
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6"> {{ entry.id }}</div>
            </div>
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.recorded_by_name") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6"> {{ entry.recorded_by_name }}</div>
            </div>
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.updated_by_name") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6"> {{ entry.updated_by_name }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.datetime") }} <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-date-range-picker
                v-if="loading"
                name="recorded_at"
                rules="required"
                v-model="entry.recorded_at"
                format="YYYY-MM-DD H:mm"
                @input="entry.recorded_at = $event"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.apartment") }} <span class="app-require">＊</span></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.customer_name") }} <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6 gr-member">
              <div class="row">
                <div class="col-xl-7 col-lg-6 col-sm-6 col-xs-6">
                  <app-input
                    disabled="true"
                    name="customer_id"
                    rules="required"
                    v-model="entry.customer_id"
                    :class="['flex-fill']"
                  />
                </div>
                <div class="col-xl-5 col-lg-6 col-sm-6 col-xs-6">
                  <button
                    @click="handleOpenCustomerModal()"
                    class="btn btn-primary w-100" style="height: 40px !important;"
                    type="button"
                  >
                    {{ $t("customer-vitals.btn_select_customer") }}
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.situation") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="situation"
                type="string128"
                v-model="entry.situation"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.temperature") }}<span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="temperature"
                type="double"
                rules="required|min_value:1|double42"
                v-model="entry.temperature"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.pressure_high") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="pressure_high"
                type="number-length:5"
                rules="max_value:65535|min_value:1"
                v-model="entry.pressure_high"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.pressure_low") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="pressure_low"
                type="number-length:5"
                rules="max_value:65535|min_value:1"
                v-model="entry.pressure_low"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.pulse_rate") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="pulse_rate"
                type="number-length:5"
                rules="max_value:65535|min_value:1"
                v-model="entry.pulse_rate"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.respiratory_rate") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="respiratory_rate"
                type="number-length:5"
                rules="max_value:65535|min_value:1"
                v-model="entry.respiratory_rate"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.spo2") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="spo2"
                type="double"
                rules="min_value:1|double52"
                v-model="entry.spo2"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.irregular_pulse") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="irregular_pulse"
                type="number-length:5"
                rules="max_value:65535|min_value:1"
                v-model="entry.irregular_pulse"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customer-vitals.blood_sugar") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="blood_sugar"
                type="number-length:5"
                rules="max_value:65535|min_value:1"
                v-model="entry.blood_sugar"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.note") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-text
                name="note"
                type="string1024"
                v-model="entry.note"
              />
            </div>
          </div>
          <div class="card-footer row justify-content-end gr-button">
            <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
              {{ $t('common.cancel') }}
            </button>

            <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary" type="submit">
              {{  $t('common.save') }}
            </button>
            <button v-else :disabled="!valid && validated" class="btn btn-primary" type="submit">
              {{  $t('common.create') }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import AppModal from "../../../components/_common/popup/AppModal";
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {ENDPOINT} from "@constants/api";
export default {
  name: "FormPage",

  components: {
    AppModal,
    AppDateRangePicker,
    "app-basic-table": AppBasicTable,
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        customer_id: '',
        apartment_id: 'all',
        recorded_at : moment().utcOffset('+0900').format('YYYY-MM-DD HH:mm')
      },
      idEditing: id,
      meta: {
        apartments: [],
        customers: [],
      },
      loading : true,
      endPoint : ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=",
      screenFlag : 1, // meal is 0, vital is 1, reservation is 2
    };
  },

  watch: {
    "entry.apartment_id": async function (value) {
      if (value && this.loading) {
        this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]=" + value

        // Clear customer id if not on list
        let checkInArr = false
        await this.$request.get(this.endPoint).then(res => {
          if (!res.hasErrors()) {
            if (res.data.data) {
              let customerId = this.entry.customer_id
              $.each(res.data.data, function(index, customer) {
                if (customer.id == customerId) {
                  checkInArr = true
                }
              });
            }
          }
          if (!checkInArr) {
            this.entry.customer_id = ''
          }
        })
      } else {
        this.endPoint = ENDPOINT.CUSTOMER_LIST + "?filters[status_enabled][equal]=1&filters[apartment_id][equal]="
        this.entry.customer_id = ''
      }
    },
  },

  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_VITAL).then(res => {
      let apartments = _.cloneDeep(res.data.apartments);
      apartments.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      this.meta = {
        apartments: apartments,
        customers: res.data.customers,
      }
    })
    if (this.idEditing) {
      this.loading = false;
      this.$request.get(this.ENDPOINT.CUSTOMER_VITAL_EDIT(this.idEditing)).then(res => {
        if (res.hasErrors()) {
          if (res.status === 'error'){
            this.__noticeError(this.$t(res.data.error_msg));
          }
          this.redirectDefaultValue()
        } else {
          this.entry = res.data.data
          this.entry.recorded_at= moment(this.entry.recorded_at).format('YYYY-MM-DD HH:mm');
          this.loading = true
        }
      })
    }
  },

  methods: {
    confirmCancel()
    {
      if (localStorage.getItem('vital_search_url')) {
        window.location.href = localStorage.getItem('vital_search_url');
        localStorage.setItem('meal_search_url', null);
      } else {
        this.__redirectBack()
      }
    },
    async createOrUpdate() {
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.CUSTOMER_VITAL_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.CUSTOMER_VITAL_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        if (res.status === 'error') {
          await this.$router.push({
            name: this.ROUTES.ADMIN.CUSTOMER_VITAL_LIST, query: {
              'filters.apartment_id.equal': 'all',
              'filters.recorded_at.between': moment().format('YYYY-MM-DD'),
            }
          });
        }
      } else {
        this.__noticeSuccess(msg);
        await this.$router.push({
          name: this.ROUTES.ADMIN.CUSTOMER_VITAL_LIST, query: {
            'filters.apartment_id.equal': 'all',
            'filters.recorded_at.between': moment().format('YYYY-MM-DD'),
          }
        });
      }
    },
    redirectBack() {
      this.$router.go(-1);
    },
    handleOpenCustomerModal() {
      this.$refs.modal_list_customer.show();
    },
    onResetAllSearch() {

    },
    onSearch() {
      // this.getTourSelectName();
    },
    selectCustomer(data) {
      this.entry.customer_id = data.row.id;
      this.$refs.modal_list_customer.hide();
    }
  },
};
</script>
